import { graphql } from "gatsby";
import React from "react";
import Pagination from "../components/Pagination";
import Layout from "../layouts";
import SEO from "../components/seo";
import ContentBlockList from "../modules/content-block-list";
import Header from "../modules/header";
import PropTypes from "prop-types";
import { format } from "date-fns";

export const query = graphql`
  query VideoPagesQuery($limit: Int, $skip: Int) {
    videos: allSanityVimeoVideo(skip: $skip, limit: $limit) {
      totalCount
      nodes {
        id
        title
        description
        _createdAt
        url {
          url
          title
          poster
        }
        topics {
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const VideoPage = props => {
  const { page, numPages } = props.pageContext;
  const data = props.data;

  const refine = nextPage => {
    window.location.href = "/videos/" + nextPage;
  };

  const pageItems = data.videos.nodes.map(node => ({
    title: node.title,
    text: node.description,
    image: node.url.poster,
    tags: node.topics
      .map(topic => {
        return topic.title;
      })
      .join(" | "),
    date: format(new Date(node._createdAt), "EEE d MMM yyyy"),
    isVideo: true,
    videoUrl: node.url.url,
  }));

  return (
    <Layout>
      <SEO
        title="GrainGrowers Videos"
        excerpt="Watch videos on key topics in the GrainGrowers world"
      />
      <Header
        title="GrainGrowers Videos"
        text="Watch videos on key topics in the GrainGrowers world"
        slug="videos"
        backgroundColour="orange"
        breadcrumbs={[{ title: "Videos", slug: "videos" }]}
      />
      <div className="container">
        <ContentBlockList content={pageItems} usePlaceholderImages={true} />
        {numPages > 1 && (
          <Pagination
            currentRefinement={page}
            nbPages={numPages}
            refine={refine}
          />
        )}
      </div>
    </Layout>
  );
};

VideoPage.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
};

export default VideoPage;
